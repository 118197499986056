import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { Icon } from '../../../common/icon'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Gewichtung3() {
  const question = {
    title: 'Gewichtung - 3. Teil',
    headline: 'Den 3.Teil hast du schon fast geschafft!',
    subline: (
      <>
        Bitte wähle hier noch <strong>zwei</strong> Dinge aus, die dir für den
        3.Teil besonders wichtig sind:
      </>
    ),
  }

  const answers = [
    {
      name: 'Wichtiger',
      id: 'beruhigend',
      value: 'beruhigend einwirken',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'trost-spenden',
      value: 'Trost spenden',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'verantwortung',
      value: 'Verantwortung tragen',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'gefordert-werden',
      value: 'gefordert werden',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'umgang',
      value: 'Umgang mit Problemen',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'Teamarbeit-alleine',
      value: 'Teamarbeit vs. alleine',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'Ausgeglichenheit',
      value: 'Ausgeglichenheit',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'Eigenstaendigkeit',
      value: 'Eigenständigkeit',
      desc: '',
    },
    {
      name: 'Wichtiger',
      id: 'bestaendigkeit-action',
      value: 'Beständigkeit vs. Action',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-red-circles">
        <Navigation />

        <section className="quiz pt-0">
          <div className="row text-center">
            <img
              className="mx-auto"
              data-src="/img/handlettering/handlettering-yes-2.svg"
              src="/img/handlettering/handlettering-yes-2.svg"
              alt="Yes!"
              width="400"
            />
            <MatchingQuestion
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="multi"
              answers={answers}
              color="turkis"
              limited
              index={'g3'}
            />
          </div>
        </section>

        <MatchingNavigation
          color="white"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-30`}
          prev={`${AREA_CARE_PATH}/spielen/frage-29`}
          index={'g3'}
        />

        <Icon position="1" name="team-3" />
        <Icon position="2" name="protect" />
        <Icon position="3" name="math" />
        <Icon position="4" name="ekg" />
      </main>
    </>
  )
}
